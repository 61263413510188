export const useText = () => {
  const user = useSanctumUser<User>();

  const { data: text } = useNuxtData<TextResponse>("text");
  const processing = useState<boolean>("processing", () => false);

  const MAX_LENGTH_FREE = 5000;
  const MAX_LENGTH_PRO = 20000;
  const MAX_LENGTH = user.value?.is_subscribed
    ? MAX_LENGTH_PRO
    : MAX_LENGTH_FREE;

  // Count number words from text
  const numberWords = (text: string) => {
    if (!text) return 0;
    return text.trim().split(/\s+/).length;
  };

  const numberCharacters = (text: string) => {
    if (!text) return 0;

    return text.length;
  };

  return {
    numberWords,
    numberCharacters,
    MAX_LENGTH,
    text,
    processing,
  };
};
