<script lang="ts" setup>
defineProps({
  text: {
    type: String,
    required: false,
    default: "",
  },
  count: {
    type: Number,
    required: false,
    default: 0,
  },
});

const { numberWords } = useText();
</script>

<template>
  <div class="text-sm" :class="text ? '' : 'text-gray-400'">
    <span class="font-bold">{{ $t("number_words.words") }}:</span>
    <span :class="text ? 'text-primary' : 'text-gray-400'">{{
      count !== 0 ? count : numberWords(text)
    }}</span>
  </div>
</template>

<style></style>
